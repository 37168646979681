.backdrop_portrait 
{
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 20px;
  position:relative;
  min-height: auto;
  align-items: start;
  justify-content: center;
  background-color: rgb(211,211,211);
 /* z-index:3;*/
}


.grid_header_portrait
{
  padding: 0.0rem;
  margin-left: 20px;
  color: rgb(36, 36, 36);
  font-weight: 400;
  text-align: center;
  word-wrap: break-word;
  overflow: clip;
}


.grid_item_portrait 
{
  padding:    1rem;
  margin:     0rem ;
  color:      rgb(0, 0, 255);
  font-size:  0.6rem;
  text-align: left;
  word-wrap:  break-word;
  overflow:   clip;
}

.portrait_image
{
  padding: 0.5rem;
  margin: 0rem ;
  background-color: rgb(211, 211, 211);
  overflow: clip;
  min-height: auto; 
}

.grid_image_full
{
  grid-column-start: 1;
  grid-column-end: 8;
  padding: 2rem;
  margin: 2rem ;
  background-color: rgb1(100, 100, 100); 
  color: rgb(0, 0, 255);
  overflow: clip;
  border-radius: 10px;
  border: 1px solid #888;
  min-height: 20rem; 
  writing-mode:horizontal-tb;
  box-shadow: 3px 3px 5px 6px #fffefe; /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */

}


.backdrop 
{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position:relative;
  min-height:auto;
  align-items: start;
  justify-content: center;
  background-color: rgb(211,211,211);
 /* z-index:3;*/
}

.grid_container
{
  display: grid;
  width: 80%;
  margin: 0 auto;

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto ;

  row-gap: 0.5rem;
  column-gap: 0rem;
  text-align: left;  
  align-items: start;
  justify-content: center;
}


.grid_header
{
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 0.5rem;
  margin-top:1rem ;
  margin-left: 1rem;
  margin-right: 1rem;
  color: rgb(48, 48, 48);
  text-align: center;
  word-wrap: break-word;
  overflow: clip;
}


.grid_item 
{
  padding:    1rem;
  margin:     0rem ;
  color:      rgb(0, 0, 255);
  font-size:  0.6rem;
  text-align: left;
  word-wrap:  break-word;
  overflow:   clip;
}

.grid_item_full 
{
  grid-column-start: 1;
  grid-column-end:8;
  padding: 0rem;
  margin: 0rem ;
  color: rgb(0, 0, 255);
  text-align: left;
  word-wrap: break-word;
  overflow: clip;
}


.grid_image_full
{
  grid-column-start: 1;
  grid-column-end: 8;
  padding: 2rem;
  margin: 2rem ;
  background-color: rgb1(100, 100, 100); 
  color: rgb(0, 0, 255);
  overflow: break;
  border-radius: 10px;
  border: 1px solid #888;
  min-height: 20rem; 
  writing-mode:horizontal-tb;
  box-shadow: 3px 3px 5px 6px #444; /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */

}


.grid_image_left
{
  grid-column-start: 1;
  grid-column-end: 2;
  padding: 3rem;
  margin: 2rem ;
  background-color: white; 
  color: rgb(0, 0, 255);
  overflow: clip;
  border-radius: 10px;
  border: 1px solid #888;
  min-height: 10rem; 
  writing-mode:horizontal-tb;
  box-shadow: 3px 3px 5px 6px #444; /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
  writing-mode:horizontal-tb;
}
.grid_image_right
{
  grid-column-start: 2;
  grid-column-end: 3;
  padding: 3rem;
  margin: 2rem ;
  background-color: white; 
  color: rgb(0, 0, 255);
  overflow: clip;
  border-radius: 10px;
  border: 1px solid #888;
  min-height: 10rem; 
  writing-mode:horizontal-tb;
  box-shadow: 3px 3px 5px 6px #444; /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
  writing-mode:horizontal-tb;
}

/* Example Media Query 
@media (max-width: 768px) {
  .grid_item {
    font-size: clamp(0.7rem, 1.2vw, 1rem);
    padding: 0.5em;
  }
}*/


/*----------------------------------------------------------*/


.text
{
  color:rgb(0,0, 255);
  font-size: 0.6rem;
}

/*.patent_image:not(:last_child) */

.patent_image 
{
  border-bottom: 5px solid #fe0505;  /* Adjust color and thickness as needed */
  margin-bottom: 15px;              /* Adjust spacing as needed */
}
