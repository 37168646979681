

.text_block 
  {
    position: relative;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #888;
    min-height: 11rem; 
    writing-mode:horizontal-tb;
    box-shadow: 3px 3px 5px 6px #444; /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
    padding: 1rem;
    margin:2rem;
    font-size: 1rem;
  }
  
  
 /* .text_block 
  {
    margin: 0 auto;
    font-size: 1rem;
  }
  */


  .bullet_point 
  {
    position: relative;
    padding-left: 20px; /* Adjust space for bullet point */
  }
  
  .bullet_point::before 
  {
    content: '•'; /* Unicode character for bullet point */
    position: absolute;
    left: 0;
    top: 0;
  }