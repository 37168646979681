

.hamburger_icon 
{
  position:fixed;
  top: 1px;
  left:1px;
  width: 16px;
  height: 16px;
  background-color: rgb(211,211,211);
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
  cursor: pointer;
  padding: 2px;
  z-index:10000;
}

.bar 
{
  height: 1px;
  width:  10px;
  padding: 1px;
  margin: 1px;
  background-color: rgb(36,36,36);
  border-radius: 1px;
  border-radius: 1px;
  transition: all 0.3s ease;
}


  
 
  .menu_list {
    display:  flex;
    position: fixed;
    flex-direction: column;
    background-color: white;
    top: 15px;
    left: 0;
    font-size: 0.5rem;
    z-index:10000;
  }
  
  .menu_list.open {
    display: block;
  }
  
  .menu_list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu_list ul li {
    padding: 1px;
    border-bottom: 1px solid #ffffff;
  }
  
  .menu_list ul li:last-child {
    border-bottom: none;
  }
  
  .menu_list ul li a {
    text-decoration: none;
    color: black;
    display: block;
  }
  
  .hamburger_icon.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .hamburger_icon.open .bar:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger_icon.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
  }
  
.hamburger_text_white
{
  position: fixed;
  top:  10px;
  left: 40px;
}
  /*background-image: url('../pictures/Backdrop Image Portrait2.jpg');  */