
.text_block 
  {
    position: relative;
    background-color: white;
    border-radius: 10px;
    border:     1px solid #888;
    min-height: auto; 
    writing-mode:horizontal-tb;
    padding:  0.5rem;
    margin:   0.2rem;
  }
  
  
.headlines
{
    font-size: 1rem; 
}

.bullet_point 
{
  position: relative;
  padding-left: 20px; /* Adjust space for bullet point */
}

.bullet_point::before 
{
  content: '•'; /* Unicode character for bullet point */
  position: absolute;
  left: 0;
  top: 0;
}


.image
{
  border: 0px solid #ccc;
  padding: 0px;
  
}

.image_shadow
{
  border-radius: 0px;
  padding: 0.5rem;
  border: 0px;
  box-shadow: 4px 4px 6px #444;
}
