  .backdrop
  {
    display: fixedx;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height:4000px;
    
  }
  
  
  /*----------------------------------------------------------*/

 
  .button, .button a{
    display: flex;               /* Enables flexbox */
    justify-content: center;     /* Centers horizontally */
    align-items: center;         /* Centers vertically */
    height: 1.5rem;
    min-width: 4rem;
    background-color: rgb(200, 200, 200);
    margin: 0.2rem;
  /*  transition: 0.1s;*/
    color: rgb(42, 42, 42);
    font-size: 1rem;
    font-weight:300;
    text-decoration: none;
  }
    
  
/*
  .button:active
  {
    background-color: rgb(246, 67, 73);
  }
*/

.button[data-selected="true"],  .button[data-selected="true"] a
  {
    background-color: rgb(238, 227, 127);
  }
 

  .button[data-selected="false"],  a
  {
    background-color: rgb(200,200,200);
  }
 

.navigation
{
  position: fixed;
  display:flex;
  flex-wrap: wrap;
  margin-top: 0px;
  column-gap: 0.5rem;
  background-color: #3e7cf8; 
  width: 100%;
  z-index:3000;
}


.bar {
  height: 3px;
  width: 100%;
  background-color: #333;
}

.menue {
  display:flex;
  flex-direction: column;
  background: rgb(255,255,255);
  position: fixed;
  top: 55px;
  left: 0;
  width:fit-content;
  padding: 5px;
  text-decoration: none;
  z-index: 100000;
  }

.menue a {
  color: rgb(0, 36, 119); /* Default color of the links */
  text-decoration: none; /* Optional: removes underline from links */
  padding: 5px;
  display: block;
}

.menue a.active {
  color: red; /* Color for the active section link */
  font-weight: bold;
  background-color: aliceblue;
}

.text
{
  text-decoration: none;
  color:rgb(135, 135, 135);
}