
.portrait_backdrop 
{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position:relative;
  min-height: auto;
  align-items: start;
  justify-content: center;  /* box-shadow: 0px 4px 8px rgba(0,0,0,0.1);*/
  background-color: rgb(211,211,211);
}
.grid_container_portrait
{
  display: grid;
  width: 90%;
  margin: 0 auto;
  grid-template-columns:  1fr;
  row-gap: 1rem;
  text-align: left;  
  align-items: start;
  justify-content: center;
}

.grid_header_portrait
{
  padding: 0.0rem;
  margin-left: 20px;
  color: rgb(36, 36, 36);
  font-weight: 400;
  text-align: center;
  word-wrap: break-word;
  overflow: clip;
}


.grid_item_portrait 
{
  padding:    1rem;
  margin:     0rem ;
  background-color: rgb(211, 211, 211);
  color:      rgb(0, 0, 255);
  font-size:  0.6rem;
  text-align: left;
  word-wrap:  break-word;
  overflow:   clip;
}



.backdrop 
{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position:relative;
  min-height: 4000px;
  align-items: start;
  justify-content: center;
  background-color: rgb(211, 211, 211);

 /* z-index:3;*/
}

.grid_container
{
  display:               grid;
  width:                 90%;
  margin:                0 auto;
  padding:               0rem;

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows:    auto auto ;

  row-gap:               1rem;
  column-gap:            0rem;
  text-align:            left;  
  align-items:           start;
  justify-content:       center;
}



.grid_header
{
  grid-column-start:  1;
  grid-column-end:    3;
  padding:            1rem;
  margin:             0rem ;
  margin-top:         2rem ;
  color:              whitesmoke;
  background-color:   #3e7cf8; 
  text-align:         center;
  word-wrap:          break-word;
  overflow:           clip;
}


.grid_item 
{
  padding: 1rem;
  margin: 0rem ;
  color: rgb(0, 0, 255);
  
  font-size: 0.6rem;
  text-align: left;
  word-wrap: break-word;
  overflow: clip;
}

/* Example Media Query 
@media (max-width: 768px) {
  .grid_item {
    font-size: clamp(0.7rem, 1.2vw, 1rem);
    padding: 0.5em;
  }
}*/


/*----------------------------------------------------------*/


.text
{
  color:rgb(0,0, 255);
  font-size: 14px;
}

