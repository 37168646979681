.backdrop_portrait 
{
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 20px;
  position:relative;
  min-height: 4000px;
  align-items: start;
  justify-content: center;
  background-color: rgb(211,211,211);
 /* z-index:3;*/
}


.grid_header_portrait
{
  padding: 0.0rem;
  margin-left: 20px;
  color: rgb(36, 36, 36);
  font-weight: 400;
  text-align: center;
  word-wrap: break-word;
  overflow: clip;
}



.grid_item_portrait 
{
  padding:    1rem;
  margin:     0rem ;
  color:      rgb(0, 0, 255);
  font-size:  0.6rem;
  text-align: left;
  word-wrap:  break-word;
  overflow:   clip;
}








.backdrop 
{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position:relative;
  min-height:2160px;
  align-items: start;
  justify-content: center;
  background-color: rgb(211,211,211);
 /* z-index:3;*/
}

.grid_container
{
  display: grid;
  width: 80%;
  margin: 0 auto;

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto ;

  row-gap:      0rem;
  column-gap:   0rem;
  text-align:   left;  
  align-items:  start;
  justify-content: center;
  font-size:    0.6rem;
}


.grid_header
{
  grid-column-start: 1;
  grid-column-end: 3;

  margin-top:1rem ;
  margin-left: 1rem;
  margin-right: 1rem;
  color: rgb(48, 48, 48);
  text-align: center;
  word-wrap: break-word;
  overflow: clip;
}

.grid_item 
{
  padding: 1rem;
  margin: 0rem ;
  color: rgb(0, 0, 255);
  font-size:  0.6rem;
  text-align: left;
  word-wrap: break-word;
  overflow: clip;
}


.grid_item_wide 
{
  grid-column: span 2;
  padding: 1rem;
  margin: 0rem ;
  color: rgb(0, 0, 255);
  font-size:  0.6rem;
  text-align: left;
  word-wrap: break-word;
  overflow: clip;
}
/* Example Media Query 
@media (max-width: 768px) {
  .grid_item {
    font-size: clamp(0.7rem, 1.2vw, 1rem);
    padding: 0.5em;
  }
}*/


/*----------------------------------------------------------*/


.text
{
  color:rgb(0,0, 255);
  font-size: 0.6rem;
}

.center
{
  text-align: center;
}