
.backdrop_portrait 
{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position:relative;
  min-height: 4000px;
  align-items: start;
  justify-content: center;
  background-color: rgb(0, 0, 0);
 /* z-index:3;*/
}


.grid_container_portrait
{
  display: grid;
  width: 100%;
  background-color: #d2d808; 
  margin: 0 auto;
  border-radius: 1rem;
  padding: 1rem;
  grid-template-columns:  1fr;
  row-gap: 1rem;
  column-gap: 0rem;
  text-align: left;  
  align-items: start;
  justify-content: center;

}

.grid_header_portrait
{
  padding: 1rem;
  margin: 0rem ;
  color: whitesmoke;
  background-color: #3e7cf8; 
  text-align: center;
  word-wrap: break-word;
  overflow: clip;
}



.backdrop 
{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-color: rgba(0, 0,0, 1);
  align-items: center;
  justify-content: center;
 /* z-index:3;*/
}

/*  position: fixed;*/

/*background-image: url('../pictures/Blockchain.webp'); */

img {
  display: block;
  margin: auto;
  width: 100%; /* This will size the image to be 50% of the parent container's width */
  height: auto; /* This maintains the aspect ratio */
}


.white_text
{
  color: cornsilk;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
