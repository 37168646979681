.backdrop_portrait 
{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position:relative;
  min-height:fit-content;
  align-items: start;
  justify-content: center;
  background-color: rgb(211,211,211);
  z-index:30;
}


.grid_container_portrait
{
  display: grid;
  width: 90%;
  margin: 0 auto;
  grid-template-columns:  1fr;
  row-gap: 1rem;
  text-align: left;  
  align-items: start;
  justify-content: center;
}

.grid_header_portrait
{
  padding:    0.5rem;
  margin:     1rem ;
  color:      rgb(36, 36, 36);
  font-size:  100;
  text-align: center;
  word-wrap:  break-word;
  overflow:   clip;
}

.grid_item_portrait 
{
  padding:    1rem;
  margin:     0rem ;
  color:      rgb(0, 0, 255);
  font-size:  0.6rem;
  text-align: left;
  word-wrap:  break-word;
  overflow:   clip;
}

.grid_image_portrait
{
  padding:0.5rem;
  margin: 1rem ;
  background-color: white; 
  color: rgb(0, 0, 255);
  font-size:  0.6rem;
  overflow: clip;
  border-radius: 10px;
  border: 1px solid #888;
  min-height: auto; 
  writing-mode:horizontal-tb;
}





.backdrop 
{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position:relative;
  min-height: 2000px;
  align-items: start;
  justify-content: center;
  background-color: rgb(211,211,211); /* z-index:3;*/
}


.grid_container
{
  display: grid;
  width: 90%;
  margin: 0 auto;

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: (auto,auto,auto,auto,auto);

  row-gap: 1rem;
  column-gap: 0rem;
  text-align: left;  
  align-items: start;
  justify-content: center;
}

.grid_item_left_row_1 
{
  grid-column: 1;
  grid-row: 1;
  padding: 1rem;
  margin: 0rem ;
  color: rgb(0, 0, 255);

  text-align: left;
  word-wrap: break-word;
  overflow: clip;
}


.grid_item_left_row_2
{
  grid-column: 1;
  grid-row: 2;
  padding: 1rem;
  margin: 0rem ;
  color: rgb(0, 0, 255);

  text-align: left;
  word-wrap: break-word;
  overflow: clip;
}


.grid_item_left_row_3 
{
  grid-column: 1;
  grid-row: 4;
  padding: 1rem;
  margin: 0rem ;
  color: rgb(0, 0, 255);

  text-align: left;
  word-wrap: break-word;
  overflow: clip;
}

.grid_item_right
{
  grid-column:      2;
  grid-row: 1/span 3;
  
  padding:     1rem;
  margin:      0rem ;
  color:       rgb(0, 0, 255);

  text-align: left;
  word-wrap: break-word;
  overflow: clip;
}
/* Example Media Query 
@media (max-width: 768px) {
  .grid_item {
    font-size: clamp(0.7rem, 1.2vw, 1rem);
    padding: 0.5em;
  }
}*/


/*----------------------------------------------------------*/


.text
{
  color:rgb(0,0, 255);
  font-size: 0.6rem;
}

