
.backdrop_portrait 
{
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 20px;
  position:relative;
  min-height: 4000px;
  align-items: start;
  justify-content: center;
  background-color: rgb(211,211,211);
 /* z-index:3;*/
}


.portrait_header
{
  padding: 0.0rem;
  margin-left: 20px;
  color: rgb(36, 36, 36);
  font-weight: 400;
  text-align: center;
  word-wrap: break-word;
  overflow: clip;
}


.portrait_item 
{
  padding:    1rem;
  margin:     0rem ;
  color:      rgb(0, 0, 255);
  font-size:  0.6rem;
  text-align: left;
  word-wrap:  break-word;
  overflow:   clip;
}


.portrait_image
{
  grid-column-start:  1;
  grid-column-end:    8;
  padding:            1rem;
  margin:             1rem ;
  background-color:   rgb(255, 255, 255); 
  color:              rgb(0, 0, 255);
  overflow:           clip;
  border-radius:      10px;
  border:             1px solid #888;
  min-height:         20rem; 
  writing-mode:       horizontal-tb;
  color:              rgb(0, 0, 255);
  font-size:          0.6rem;
}


.backdrop 
{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position:relative;
  min-height: auto;
  align-items: start;
  justify-content: center;
 /* z-index:3;*/
}


.grid_container
{
  display: grid;
  width: 90%;
  
  background-color: rgb(211,211,211);

  margin: 0 auto;
  border-radius: 1rem;
  padding: 1rem;

  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: (auto, auto, auto, auto, auto) ;

  row-gap: 1rem;
  column-gap: 0rem;
  text-align: left;  

  align-items: start;
  justify-content: center;
}

.grid_header
{
  grid-column: 1/ span 3;
  grid-row: 1/2;

  padding: 1rem;
  margin: 0rem ;
  color: whitesmoke;
  background-color: #3e7cf8; 
  text-align: center;
  word-wrap: break-word;
  overflow: clip;
}



.grid_item_left_row_2
{
  padding: 1rem;
  margin: 0rem ;

  grid-column: 1/2;
  grid-row: 2/3;

  color: rgb(0, 0, 255);
  text-align: left;
  word-wrap: break-word;
  overflow: clip;
}


.grid_item_left_row_3
{
  padding: 1rem;
  margin: 0rem ;

  grid-column: 1/2;
  grid-row: 3/4;

  color: rgb(0, 0, 255);
  text-align: left;
  word-wrap: break-word;
  overflow: clip;
}


.grid_item_right
{
  grid-column:      2;
  grid-row: 2/span 4;
  
  padding:     1rem;
  margin:      0rem ;
  color:       rgb(0, 0, 255);

  text-align: left;
  word-wrap: break-word;
  overflow: clip;
}

/* Example Media Query 
@media (max-width: 768px) {
  .grid_item {
    font-size: clamp(0.7rem, 1.2vw, 1rem);
    padding: 0.5em;
  }
}*/


/*----------------------------------------------------------*/


.text
{
  color:rgb(0,0, 255);
  font-size: 14px;
}

