
.text_block 
  {
    position: relative;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #888;
    min-height: auto; 
    writing-mode:horizontal-tb;
    box-shadow: 3px 3px 5px 6px #444; /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
    padding:   1rem;
    margin:    1rem;
    font-size: 0.6rem;
  }
  
  
.headlines
{
    font-size: 1.5rem; 
}

.bullet_point 
{
  position: relative;
  padding-left: 20px; /* Adjust space for bullet point */
}

.bullet_point::before 
{
  content: '•'; /* Unicode character for bullet point */
  position: absolute;
  left: 0;
  top: 0;
}

.multi_column_2 
{
  -webkit-column-count: 2;             /* Chrome, Safari, Opera*/
  -moz-column-count: 2;                /* Firefox */
  column-count: 2;

  -webkit-column-gap: 1rem;           /* Chrome, Safari, Opera*/
  -moz-column-gap: 1rem;               /* Firefox */
  column-gap: 1rem;

  font-size: 0.6rem;
}


.multi_column_3 
{
  -webkit-column-count: 3;             /* Chrome, Safari, Opera*/
  -moz-column-count: 3;                /* Firefox */
  column-count: 3;

  -webkit-column-gap: 2rem;           /* Chrome, Safari, Opera*/
  -moz-column-gap: 2rem;               /* Firefox */
  column-gap: 2rem;
  font-size: 0.6rem;
}

.multi_column_4 
{
  -webkit-column-count: 4;             /* Chrome, Safari, Opera*/
  -moz-column-count: 4;                /* Firefox */
  column-count: 4;

  -webkit-column-gap: 2rem;           /* Chrome, Safari, Opera*/
  -moz-column-gap: 2rem;               /* Firefox */
  column-gap: 2rem;

  font-size: 0.6rem;
}
