/* CustomButton.css */


/* scss-docs-start color-variables
$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;
*/


.my_button
{
    min-height: 1.2rem;
    height: auto;
    margin: 0.0rem ;
    border:none;
    font-weight:400;
    font-size: 0.7rem;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border-radius: 5px; 
    background-color: #0d6efd; 
    color:rgb(255,255, 255);
    transition: background_color 0.3s ease;
}


.my_button.clicked 
{
    transform: scale(0.95);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box_shadow 0.1s ease;
}
  
.my_button.disabled
{
    background-color: rgb(100,100, 100); 
    cursor: not_allowed;
    opacity: 0.5;
}

.my_button.selected
{
    background-color: #0000ff; 
    cursor: pointer;
    opacity: 1;
}